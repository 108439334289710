import Matter from "matter-js";

export function init() {
    /* INTRO -- MATTER JS */
    let Engine = Matter.Engine,
        Render = Matter.Render,
        Runner = Matter.Runner,
        Constraint = Matter.Constraint,
        Events = Matter.Events,
        Mouse = Matter.Mouse,
        World = Matter.World,
        Bodies = Matter.Bodies;

    // create engine
    let engine = Engine.create(),
        world = engine.world;

    engine.world.wireframes = false;
    engine.gravity.x = 0.0;
    engine.gravity.y = 0.0;
    engine.timing.timeScale = 1

    const debug_collisions = 0;
    const ball_radius = 25;
    const ball_gravity = 0.9;
    const slingshot_force = 100;
    const parentDiv = document.getElementById("matter");
    let w = 680;
    let h = 480;

    let play = false;

    // create renderer
    let render = Render.create({
        element: parentDiv,
        engine: engine,
        options: {
            bounds: true,
            showBounds: false,
            background: "transparent",
            wireframes: false,
            width: w,
            height: h
        }
    });

    Render.run(render);

    let runner = Runner.create();
    Runner.run(runner, engine);

    let mouse = Mouse.create(render.canvas);
    let mouseIsDown = false;
    render.mouse = mouse;

    const staticOpts = {
        isStatic: true,
        render: {
            fillStyle: "#f0da54"
        }
    }

    // BUMPERS
    // const bumpersOpts = {
    //     isStatic: true,
    //     render: {
    //         // fillStyle: "#f05454"
    //         fillStyle: "#f0da54"
    //     },
    //     restitution: 1
    // };
    // World.add(world, [Bodies.circle(w / 2, h / 3, 20, bumpersOpts)]);
    // World.add(world, [Bodies.circle(w / 3, h / 4, 20, bumpersOpts)]);
    // World.add(world, [Bodies.circle(w / 1.5, h / 4, 20, bumpersOpts)]);

    // SLINGSHOT
    let anchor = { x: w - 45, y: h - 5 },
        sling = Bodies.rectangle(w - 45, h - 5, w / 12, 10, { density: 1.5, render: { fillStyle: "#f0da54" } }),
        elastic = Constraint.create({
            pointA: anchor,
            bodyB: sling,
            length: 10,
            stiffness: 0.08,
            render: {
                strokeStyle: "#f0da54"
            }
        });


    let bodiesFlip = [];
    let bodiesBanana = [];

    let categories = {
        catMouse: 0x0002,
        catBody: 0x0004
    };
    // // LETTRES BANANA
    let startingPosition2 = [];
    const head2 = document.querySelector(".text-to-canvas h2");
    let letters2 = head2.querySelectorAll("span");
    for (let i = 0; i < letters2.length; i++) {
        bodiesBanana.push(
            Bodies.rectangle(
                w / 2 - (letters2.length * letters2[i].offsetWidth * 1.3) / 2 + i * letters2[i].offsetWidth + 96,
                h / 2 - 50,
                letters2[i].offsetWidth * 0.75,
                letters2[i].offsetHeight * 2.2,
                {
                    isSleeping: false,
                    density: 0.05,
                    restitution: 0.7,
                    // frictionAir: 0.0001,
                    collisionFilter: {
                        category: categories.catBody
                    },
                    render: {
                        opacity: debug_collisions
                    }
                }
            )
        );
        startingPosition2.push(bodiesBanana[i].position);
    }
    // LETTRES FLIP
    const head = document.querySelector(".text-to-canvas h1");
    let letters = head.querySelectorAll("span");
    for (let i = 0; i < letters.length; i++) {
        bodiesFlip.push(
            Bodies.rectangle(
                w / 2 - (letters.length * letters[i].offsetWidth) / 2 + i * letters[i].offsetWidth + 32,
                h / 1.5,
                letters[i].offsetWidth * 0.75,
                letters[i].offsetHeight * 2.2,
                {
                    isSleeping: false,
                    density: 0.05,
                    restitution: 0.7,
                    // frictionAir: 0.01,
                    collisionFilter: {
                        category: categories.catBody
                    },
                    render: {
                        opacity: debug_collisions
                    }
                }
            )
        );
    }
    //ball
    let ball = Bodies.circle(w - 50, h - 100, ball_radius,
        {
            isSleeping: false,
            density: 1.5,
            restitution: 0.75,
            frictionAir: 0.001,
            collisionFilter: {
                category: categories.catBody
            },
            render: {
                opacity: 1,
                fillStyle: "#f0da54"
            }
        });

    World.add(world, bodiesFlip);
    World.add(world, bodiesBanana);

    Render.lookAt(render, {
        min: { x: 0, y: 0 },
        max: { x: w, y: h }
    });


    /* KEYBOARD HANDLER */
    // const keyHandlers = {
    // KeyD: () => {
    //     Matter.Body.setVelocity(bodiesBanana[0], velocityToTarget(bodiesBanana[0], startingPosition2[0]));
    // bodiesBanana.forEach(b => {
    // Matter.Body.setVelocity(b, velocityToTarget(b, startingPosition2[i]));
    //     i++;
    // })
    // },
    // KeyB: () => {
    //     Matter.Body.setPosition(ball, {
    //         x: w - 20,
    //         y: h - 100
    //     });

    //     Matter.Body.setVelocity(ball, { x: 0, y: 0 });
    // },
    // };

    // const keysDown = new Set();
    // document.addEventListener("keydown", event => {
    //     keysDown.add(event.code);
    // });
    // document.addEventListener("keyup", event => {
    //     keysDown.delete(event.code);
    // });


    Events.on(engine, "beforeUpdate", e => {
        // [...keysDown].forEach(k => {
        //     keyHandlers[k]?.();
        // });

        // APLLY GRAVITY TO BALL
        if (play) {
            Matter.Body.applyForce(ball, {
                x: ball.position.x,
                y: ball.position.y
            }, { x: 0.0, y: ball_gravity })
        }

        // SLINGSHOT FIXED ROTATION
        Matter.Body.setAngularVelocity(sling, 0);
    });

    function update() {
        requestAnimationFrame(update);

        // SPRING CONTROL
        if (mouseIsDown && play) releaseSpring();

        // FLIP
        for (var i = 0; i < letters.length; i++) {
            letters[i].style.transform = "translate("
                + (bodiesFlip[i].position.x - letters[i].offsetWidth * 0.5) + "px,"
                + (bodiesFlip[i].position.y - letters[i].offsetHeight * 0.5) + "px)"
                + " rotate(" + bodiesFlip[i].angle + "rad)";
        }

        // BANANA
        for (i = 0; i < letters2.length; i++) {
            letters2[i].style.transform = "translate("
                + (bodiesBanana[i].position.x - letters2[i].offsetWidth * 0.5) + "px,"
                + (bodiesBanana[i].position.y - letters2[i].offsetHeight * 0.5) + "px)"
                + " rotate(" + bodiesBanana[i].angle + "rad)";
        }
    }

    update();

    function pushButtons(but, isDown = false) {
        if (but === "start") {
            if (!play) {
                startPlayMode();
                play = true;
            } else {
                Matter.Body.setPosition(ball, {
                    x: w - 50,
                    y: h - 100
                });

                Matter.Body.setVelocity(ball, { x: 0, y: 0 });
            }
        }
        if (but === "launch") {
            console.log(play)
            if (play) {
                if (isDown)
                    mouseIsDown = true;
                else
                    mouseIsDown = false;
            }
        }
    }


    function startPlayMode() {
        // BORDERS // BOTTOM LEFT / BOTTOM RIGHT / UNDER SLINGSHOT / LEFT / RIGHT
        World.add(world, [Bodies.rectangle(w / 5.5, h + 25, w / 3, 80, { isStatic: true, angle: 5 * Math.PI / 180, render: { fillStyle: "#f0da54" } })]);
        World.add(world, [Bodies.rectangle(w - w / 3.5, h + 25, w / 3, 80, { isStatic: true, angle: -5 * Math.PI / 180, render: { fillStyle: "#f0da54" } })]);
        World.add(world, [Bodies.rectangle(w - w / 12, h + 20, w / 8, 60, staticOpts)]);
        World.add(world, [Bodies.rectangle(10, h / 2, 20, h, staticOpts)]);
        World.add(world, [Bodies.rectangle(w - 10, h / 2, 20, h, staticOpts)]);

        roundedCorners(0, 1);

        World.add(world, [Bodies.rectangle(w / 2, -5, w, 40, { isStatic: true, render: { fillStyle: "#f0da54" } })]);

        roundedCorners(w, -1);

        // RAMP
        World.add(world, [Bodies.rectangle(w - w / 8, h / 2 + h / 4, 15, h - h / 3, staticOpts)]);
        // PLUNGER
        World.add(world, elastic);
        World.add(world, sling);
        // BALL
        World.add(world, ball);
        Matter.Body.setVelocity(ball, { x: 0, y: 0 });
    }

    function releaseSpring(forceMultiplier = 1) {
        Matter.Body.applyForce(sling, {
            x: sling.position.x,
            y: sling.position.y
        }, { x: 0.0, y: -slingshot_force * forceMultiplier })
    }

    function velocityToTarget(from, to, speed = 1) {
        const direction = Math.atan((to.x - from.x) / (to.y - from.y));
        const speed2 = to.y >= from.y ? speed : -speed;

        return { x: speed2 * Math.sin(direction), y: speed2 * Math.cos(direction) };
    }

    function roundedCorners(ox, sx) {
        World.add(world, [Bodies.rectangle(ox + sx * 2, 85, 40, 50, { isStatic: true, angle: sx * 10 * Math.PI / 180, render: { fillStyle: "#f0da54" } })]);
        World.add(world, [Bodies.rectangle(ox + sx * 0, 75, 40, 50, { isStatic: true, angle: sx * 15 * Math.PI / 180, render: { fillStyle: "#f0da54" } })]);
        World.add(world, [Bodies.rectangle(ox + sx * 10, 55, 40, 50, { isStatic: true, angle: sx * 30 * Math.PI / 180, render: { fillStyle: "#f0da54" } })]);
        World.add(world, [Bodies.rectangle(ox + sx * 20, 35, 40, 50, { isStatic: true, angle: sx * 45 * Math.PI / 180, render: { fillStyle: "#f0da54" } })]);
        World.add(world, [Bodies.rectangle(ox + sx * 40, 15, 40, 50, { isStatic: true, angle: sx * 60 * Math.PI / 180, render: { fillStyle: "#f0da54" } })]);
        World.add(world, [Bodies.rectangle(ox + sx * 70, 0, 40, 50, { isStatic: true, angle: sx * 75 * Math.PI / 180, render: { fillStyle: "#f0da54" } })]);
    }

    return pushButtons;
}
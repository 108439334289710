import React from "react";
import sanityClient from "../client.js";
import BlockContent from '@sanity/block-content-to-react';


class ListPinballs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            curID: 0,
            curSlide: 1,
            clicked: false,
            activeProduct:
            {
                body: {
                    _type: "block"
                },
                images: [{
                    asset: {
                        url: ""
                    }
                }],
                mainImage: {
                    asset: {
                        url: ""
                    }
                },
                secondImage: {
                    asset: {
                        url: ""
                    }
                },
                thirdImage: {
                    asset: {
                        url: ""
                    }
                },
                fourthImage: {
                    asset: {
                        url: ""
                    }
                },
                fifthImage: {
                    asset: {
                        url: ""
                    }
                }
            },
            autoShowTime: 3000,
            products: []
        };
        this.timer = 0;

        this.serializers = {
            types: {
                code: props => (
                    <pre data-language={props.node.language}>
                        <code>{props.node.code}</code>
                    </pre>
                )
            }
        };

        this.setActive = this.setActive.bind(this);
        this.startAutoShow = this.startAutoShow.bind(this);
        this.stopAutoShow = this.stopAutoShow.bind(this);
        this.onChangeProduct = this.onChangeProduct.bind(this);
        this.showSlide = this.showSlide.bind(this);
        this.onChangeSlide = this.onChangeSlide.bind(this);
        this.onEnterSlideshow = this.onEnterSlideshow.bind(this);
        this.onLeaveSlideshow = this.onLeaveSlideshow.bind(this);
    }

    componentDidMount() {
        sanityClient.fetch(`*[_type == 'product']| order(order asc){
            _id,
            order,
            title,
            brand,
            body,
            soon,
            price_weekend,
            price_week,
            price_2weeks,
            price_month,
            mainImage {
                asset->{
                    _id,
                    url
                },
                alt
            },  
            secondImage {
                asset->{
                    _id,
                    url
                },
                alt
            },  
            thirdImage {
                asset->{
                    _id,
                    url
                },
                alt
            },
            fourthImage {
                asset->{
                    _id,
                    url
                },
                alt
            }, 
            fifthImage {
                asset->{
                    _id,
                    url
                },
                alt
            },
        }`)
            .then(data => {
                // ADD IMAGES TO ARRAY
                data.forEach(p => {
                    p.images = [];
                    if (p.mainImage)
                        p.images.push(p.mainImage);
                    if (p.secondImage)
                        p.images.push(p.secondImage);
                    if (p.thirdImage)
                        p.images.push(p.thirdImage);
                    if (p.fourthImage)
                        p.images.push(p.fourthImage);
                    if (p.fifthImage)
                        p.images.push(p.fifthImage);
                })

                this.setState({ products: data }, () => {
                    this.setActive(null, this.state.products[0]);
                    this.startAutoShow();
                });
            })
            .catch(console.error);

    }
    componentWillUnmount() {
        this.stopAutoShow();
    }

    render() {
        return (
            <div className="row my-5">
                <div className="col-lg-5">
                    <ul className="bf-pinball-list" onMouseEnter={this.onEnterSlideshow} onMouseLeave={this.onLeaveSlideshow}>
                        {
                            this.state.products.map((item) => {
                                return <li key={item._id} data-id={item._id}
                                    className={`bf-list-element ${this.state.activeProduct._id === item._id ? 'active' : ''}`}
                                    onClick={this.setActive}>
                                    <span className="bf-list-item-content">
                                        <h5>{item.title}</h5>
                                        <small>{item.brand}</small>
                                    </span>
                                    {item.soon ? <span className="bf-item-soon">BIENTÔT !</span> : ''}
                                </li>;
                            }, this)
                        }
                    </ul>
                </div>
                <div className="col-lg-7" onMouseEnter={this.onEnterSlideshow} onMouseLeave={this.onLeaveSlideshow}>

                    <div className="slideshow-container">
                        {
                            this.state.activeProduct.images.map((img, i) => {
                                return <div key={i} className="slides fade">
                                    <img
                                        className="img-fluid rounded mb-4"
                                        src={img.asset.url}
                                        alt=""
                                    />
                                </div>
                            })
                        }

                        <button className="prev" onClick={() => { this.onChangeSlide(-1) }}>&#10094;</button>
                        <button className="next" onClick={() => { this.onChangeSlide(1) }}>&#10095;</button>

                    </div>
                    <h3>{this.state.activeProduct.title} <span className="bf-item-soon">{this.state.activeProduct.soon ? 'BIENTÔT !' : ''}</span></h3>
                    <div>
                        <BlockContent blocks={this.state.activeProduct.body} projectId="552cpgqt" dataset="production" serializers={this.serializers} />
                    </div>

                    {this.state.activeProduct.price_weekend || this.state.activeProduct.price_week ||
                        this.state.activeProduct.price_2weeks || this.state.activeProduct.price_month ?
                        <h3 className="my-4">Tarifs location</h3>
                        : ''}
                    <div className="row">
                        {this.state.activeProduct.price_weekend ?
                            <div className="col-lg-3 col-sm-6 bf-price-tag">
                                <div className="bf-price-tag-header">WEEKEND</div>
                                <p>{this.state.activeProduct.price_weekend} €</p>
                            </div>
                            : ''}
                        {this.state.activeProduct.price_week ?
                            <div className="col-lg-3 col-sm-6 bf-price-tag">
                                <div className="bf-price-tag-header">SEMAINE</div>
                                <p>{this.state.activeProduct.price_week} €</p>
                            </div>
                            : ''}
                        {this.state.activeProduct.price_2weeks ?
                            <div className="col-lg-3 col-sm-6 bf-price-tag">
                                <div className="bf-price-tag-header">2 SEMAINE</div>
                                <p>{this.state.activeProduct.price_2weeks} €</p>
                            </div>
                            : ''}
                        {this.state.activeProduct.price_month ?
                            <div className="col-lg-3 col-sm-6 bf-price-tag">
                                <div className="bf-price-tag-header">MOIS</div>
                                <p>{this.state.activeProduct.price_month} €</p>
                            </div>
                            : ''}
                    </div>

                    <h4 className="my-4">Tarifs déplacement, installation : <strong>1.80€ / Km</strong></h4>

                    {!this.state.activeProduct.soon ? <div className="bf-button my-4"><a href={`/contact?id=${this.state.activeProduct._id}`}>RESERVER</a></div> : ''}

                </div>
            </div>
        );
    }


    setActive(e, prod = null) {
        let product = null
        if (e != null) {
            // CLICK EVENT
            if (this.timer !== 0) this.stopAutoShow();

            const clickedID = e.target.dataset.id;

            this.setState({ clicked: true });

            this.state.products.forEach(p => {
                if (p._id === clickedID) {
                    product = p;
                    return;
                }
            });

            // ANCHOR SCROLL DOWN TO SLIDESHOW ON MOBILE
            if (window.innerWidth < 992) {
                // const slideshowScroll = document.getElementsByClassName("slideshow-container")[0].clientHeight;
                const slideshowScroll = document.getElementsByClassName("slideshow-container")[0].getBoundingClientRect().top
                // console.log(slideshowScroll);
                // window.scrollTop = slideshowScroll;
                window.scrollTo({
                    top: slideshowScroll,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }
        // NOT CLICK EVENT
        if (prod != null) product = prod;
        if (product === null) console.warn("!! PRODUCT NOT FOUND !!");
        if (parseInt(this.state.activeProduct._id, 10) === product._id) {
            this.setState({
                activeProduct: false
            });
        } else {
            this.setState({
                activeProduct: product,
                curSlide: 1
            }, () => {
                this.showSlide(1);
            });
        }
        if (!this.state.activeProduct) {
            this.stopAutoShow();
        }
    }

    startAutoShow() {
        if (this.timer === 0 && !this.state.clicked) {
            this.timer = setInterval(this.onChangeProduct, this.state.autoShowTime);
        }
    }
    onChangeProduct() {
        let next = this.state.activeProduct.order + 1;
        if (next >= this.state.products.length) {
            next = 0;
        }

        this.setActive(null, this.state.products[next]);
    }
    stopAutoShow() {
        clearInterval(this.timer);
        this.timer = 0;
    }

    onChangeSlide(n) {
        let curSlide = this.state.curSlide + n
        var slides = document.getElementsByClassName("slides");
        if (curSlide > slides.length) {
            curSlide = 1;
        }
        if (curSlide < 1) {
            curSlide = slides.length;
        }
        if (this.timer !== 0) this.stopAutoShow();
        this.setState({ curSlide: curSlide }, () => {
            this.showSlide();
        });
    }
    onEnterSlideshow(e) {
        this.stopAutoShow();
    }
    onLeaveSlideshow(e) {
        this.startAutoShow();
    }
    showSlide() {
        var slides = document.getElementsByClassName("slides");
        for (let i = 0; i < slides.length; i++) {
            if (typeof slides[i] !== undefined) {
                slides[i].style.display = "none";
            }
        }
        if (typeof slides[this.state.curSlide - 1] !== undefined) slides[this.state.curSlide - 1].style.display = "block";

    }

}


export default ListPinballs;
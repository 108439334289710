import React from "react";
import axios from 'axios';
import ReCaptchaV2 from 'react-google-recaptcha';
import sanityClient from "../client.js";
import { motion } from "framer-motion";

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.9] };

class Contact extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            name: '',
            surname: '',
            email: '',
            subject: '',
            message: '',
            tel: '',
            selectedProductID: this.getQueryVariable("id"),
            productInfo: {},
            messageStatut: '',
            type: 0
        }
    }

    componentDidMount() {

        if (this.state.selectedProductID) {
            sanityClient.getDocument(this.state.selectedProductID)
                .then(data => {
                    this.setState({ subject: "Réservation " + data.title });
                })
                .catch(console.error);

            this.setState({ type: 3 });
        }
    }

    render() {
        return (
            <motion.div className="page" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0, transition: transition }}>
                <div className="container">
                    <div className="row align-items-center my-5">
                        <div className="col-lg-6">
                            <img
                                className="img-fluid rounded mb-4 mb-lg-0"
                                src="/img/content/bananaflip-contact-flipper-location-brest.jpg"
                                alt=""
                            />
                        </div>
                        <div className="col-lg-6">
                            <h1 className="font-weight-light">Contact</h1>

                            <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST" autoComplete="on">
                                <div className="row form-group">
                                    <div className="col">
                                        <label htmlFor="surname">Nom</label>
                                        <input type="text" name="family-name" className="form-control" value={this.state.surname} onChange={this.onSurnameChange.bind(this)} required />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="name">Prénom</label>
                                        <input itype="text" name="given-name" className="form-control" value={this.state.name} onChange={this.onNameChange.bind(this)} required />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Votre adresse mail</label>
                                    <input type="email" id="email" name="email" className="form-control" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="tel">Votre numéro de téléphone</label>
                                    <input type="tel" name="tel" className="form-control" aria-describedby="telHelp" value={this.state.tel} onChange={this.onTelChange.bind(this)} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="requestType">Votre type de demande</label>
                                    <select className="custom-select my-1 mr-sm-2" id="requestType" value={this.state.type} onChange={this.onTypeChange.bind(this)}>
                                        <option value="1">Dépannage à domicile</option>
                                        <option value="2">Réparation</option>
                                        <option value="3">Location</option>
                                        <option value="4">Restauration</option>
                                        <option value="5">Information pièce</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="subject">Le sujet de votre demande</label>
                                    <input type="text" className="form-control" aria-describedby="emailHelp" value={this.state.subject} onChange={this.onSubjectChange.bind(this)} required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Message</label>
                                    <textarea className="form-control" rows="5" value={this.state.message} onChange={this.onMessageChange.bind(this)} required />
                                </div>
                                <ReCaptchaV2
                                    sitekey={process.env.REACT_APP_SITE_KEY}
                                    onChange={this.handleToken.bind(this)}
                                    onExpired={this.handleExpire.bind(this)}
                                />
                                <button id="btn-submit" type="submit" className="btn btn-primary" disabled={this.state.messageStatut} >Envoyer</button>
                                <p className="alert-success">{this.state.messageStatut}</p>
                            </form>

                        </div>
                    </div>
                </div>
            </motion.div>
        );
    }

    onNameChange(event) {
        this.setState({ name: event.target.value })
    }
    onSurnameChange(event) {
        this.setState({ surname: event.target.value })
    }

    onEmailChange(event) {
        this.setState({ email: event.target.value })
    }
    onSubjectChange(event) {
        this.setState({ subject: event.target.value })
    }

    onMessageChange(event) {
        this.setState({ message: event.target.value })
    }

    onTelChange(event) {
        this.setState({ tel: event.target.value })
    }

    onTypeChange(event) {
        this.setState({ type: event.target.value })
    }

    handleSubmit(event) {
        event.preventDefault();

        axios({
            method: "POST",
            url: window.location.protocol + "//admin.bananaflip.fr/send.php",
            data: this.state
        }).then((response) => {
            if (response.data.status === 'success') {
                this.setState({
                    name: '',
                    surname: '',
                    email: '',
                    subject: '',
                    message: '',
                    tel: '',
                    selectedProductID: this.getQueryVariable("id"),
                    productInfo: {},
                    messageStatut: 'Message envoyé !',
                    type: 0
                });
            } else if (response.data.status === 'fail') {
                this.setState({
                    name: '',
                    surname: '',
                    email: '',
                    subject: '',
                    message: '',
                    tel: '',
                    selectedProductID: this.getQueryVariable("id"),
                    productInfo: {},
                    messageStatut: 'Echec de l\'envoi du message.',
                    type: 0
                });
            }
        })
    }

    handleToken(token) {
        this.setForm((currentForm) => {
            return { ...currentForm, token }
        })
    }

    handleExpire() {
        this.setForm((currentForm) => {
            return { ...currentForm, token: null }
        })
    }

    getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] === variable) { return pair[1]; }
        }
        return (false);
    }
}

export default Contact;
import React from "react";
import * as HomeIntro from "./homeIntro";
import { motion } from "framer-motion"

class Home extends React.Component {

    transition = { duration: 1.2, ease: [0.43, 0.13, 0.23, 0.9] };
    transition2 = { duration: 2, type: "spring", bounce: 0.4 };

    componentDidMount() {
        this.pinballButtons = HomeIntro.init();
    }

    render() {
        return (
            <motion.div className="home"
            // exit={{ opacity: 1, transition: { delay: 0.5, ...this.transition } }}
            >
                <motion.div id="intro-container" className="container-fluid bg-dark"
                    initial={{ opacity: 1, paddingTop: 50 }}
                    animate={{ opacity: 1, paddingTop: 0, transition: this.transition }}
                    exit={{ opacity: 0, transition: { delay: 0.5, ...this.transition } }}
                >
                    <div className="row align-items-center force-maxwidth">
                        <motion.div className="col-lg-6"
                            initial={{ opacity: 0, x: -200 }}
                            animate={{ opacity: 1, x: 0, transition: { delay: 1, ...this.transition2 } }}
                        >
                            <div id="bf-pinball">
                                <div id="matter">
                                    <div className="text-to-canvas">
                                        <h2>
                                            <span>B</span>
                                            <span>A</span>
                                            <span className="off">N</span>
                                            <span>A</span>
                                            <span>N</span>
                                            <span className="off">A</span>
                                        </h2>
                                        <h1>
                                            <span>F</span>
                                            <span>L</span>
                                            <span>I</span>
                                            <span className="off">P</span>
                                        </h1>
                                    </div>
                                </div>
                                <div className="bf-pinball-button" onMouseDown={() => this.pinballButtons("launch", true)} onMouseUp={() => this.pinballButtons("launch", false)}
                                    onTouchStart={() => this.pinballButtons("launch", true)} onTouchEnd={() => this.pinballButtons("launch", false)}></div>
                                <div className="bf-pinball-button" onMouseUp={() => { this.pinballButtons("start") }}></div>
                            </div>

                        </motion.div>

                        <div className="col-lg-5 px-5">
                            <h1 className="font-weight-bold">RÉPARATION & LOCATION DE FLIPPER À BREST</h1>
                            <p>
                                Banana Flip, c’est votre spécialiste flipper dans la région Bretagne. De la caisse au plateau, en passant par l’électronique,
                                nous nous occupons de l’entretien et le dépannage de votre machine pour vous permettre d’en profiter toute l’année, sans prise de tête.
                                Location de flippers disponible pour particuliers et entreprises, n’hésitez pas à nous contacter.
                            </p>
                            <div className="bf-button">
                                <a href="/Location">Voir les flippers</a>
                            </div>
                        </div>
                    </div>
                </motion.div >
                <div className="container my-5">

                    <div className="row align-items-center justify-content-center">
                        <h2 className="bf-big-title">LOCATION</h2>
                    </div>

                    <div className="container my-2">
                        <div className="row align-items-center justify-content-center ">
                            <div className="col-lg-12 my-5">
                                <img
                                    className="img-fluid rounded mb-4 mb-lg-0"
                                    src="/img/content/bananaflip-location-wms-williams-brest.jpg"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="row justify-content-center align-items-center mt-2">
                            <div className="col-lg-8">
                                <p className="intro-p">
                                    <strong>Banana Flip</strong> vous propose ses flippers en location, pour particuliers ou entreprises.
                                    Choisissez parmi les machines disponibles celle qui correspond à votre envie. Durée variable, du week-end au mois, suivant vos besoins.
                                </p>
                                <div className="bf-button justify-content-center">
                                    <a href="/Services#location">Voir plus</a>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row align-items-center my-7">
                        <div className="col-lg-6">
                            <h2 className="font-weight-bold">Entretien / Révision</h2>
                            <p>
                                Banana Flip vous propose un forfait "Entretien / Révision", véritable "check up" de votre machine, la révision comprend un nettoyage et
                                polissage du plateau, la vérification du bon fonctionnement des mécanismes et le remplacement des pièces d’usure si besoin.
                            </p>
                            <div className="bf-button justify-content-center">
                                <a href="/Services#entretien">Voir plus</a>
                            </div>

                        </div>
                        <div className="col-lg-6">
                            <img
                                className="img-fluid rounded mb-4 mb-lg-0"
                                src="/img/content/bananaflip-entretien-brest-bally-electromecanique.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-7">
                        <div className="col-lg-7">
                            <img
                                className="img-fluid rounded mb-4 mb-lg-0"
                                src="/img/content/bananaflip-reparation-depannage-wiring-cablage-electronique.jpg"
                                alt=""
                            />
                        </div>
                        <div className="col-lg-5">
                            <h2 className="font-weight-bold">Dépannage / Réparation</h2>
                            <p>
                                Un dysfonctionnement sur votre flipper vous empêche d’y jouer ? Banana Flip intervient à domicile pour le dépannage ou l’entretien sur
                                votre machine.
                            </p>
                            <div className="bf-button justify-content-center">
                                <a href="/Services#depannage">Voir plus</a>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center my-7">
                        <div className="col-lg-7">
                            <h2 className="font-weight-bold">Restauration</h2>
                            <p>
                                Après des années de service dans les bars enfumés et de multiples déplacements, certains flippers méritent un bon rafraichissement pour
                                commencer leur nouvelle vie dans votre salon. De la caisse au plateau, Banana Flip s’occupe de tout pour redonner à votre machine son aspect
                                d’origine.
                            </p>
                            <div className="bf-button justify-content-center">
                                <a href="/Services#restauration">Voir plus</a>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <img
                                className="img-fluid rounded mb-4 mb-lg-0"
                                src="/img/content/bananaflip-restauration-gottlieb-stargate-brest.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-7">
                        <div className="col-lg-6">
                            <img
                                className="img-fluid rounded mb-4 mb-lg-0"
                                src="/img/content/bananaflip-customisation-leds-williams-brest.jpg"
                                alt=""
                            />
                        </div>
                        <div className="col-lg-6">
                            <h2 className="font-weight-bold">Customisation</h2>
                            <p>
                                Envie de personnaliser l’aspect de votre flipper ? Banana Flip vous propose son service de customisation sur mesure. Des stickers de caisse aux
                                graphismes originaux, des modifications d’éclairage unique ou encore des gadgets innovants, faites-vous plaisir en customisant votre machine.
                            </p>
                            <div className="bf-button justify-content-center">
                                <a href="/Services#customisation">Voir plus</a>
                            </div>
                        </div>
                    </div>
                </div>

            </motion.div >
        );
    }
}

export default Home;
import React from "react";
import { motion } from "framer-motion";

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.9] };

class Legal extends React.Component {


    render() {
        return (
            <motion.div className="page" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0, transition: transition }}>
                <div className="container">
                    <div className="row align-items-center my-5">
                        <div className="col-md-12">
                            <h2>Banana Flip - TEISSEIRE Adrien</h2>
                            <p>29200 BREST</p>
                            <p>SIRET : 90883980600019</p>
                            <p>Auto-entreprise enregistrée à l'INSEE le 03-01-2022</p>
                            <p>Tél : 07.82.08.90.81</p>
                            <p>www.bananaflip.fr</p>
                            <h3>Création graphique &amp; web</h3>
                            <p>Thomas SAULAY - La Rocade</p>
                            <p><a rel="noreferrer" href="http://larocade.com" target="_blank">www.larocade.com</a></p>
                            <p>thomas.saulay [AT] gmail [DoT] com</p>
                            <h3>Hébergement</h3>
                            <p>OVH</p>
                            <p>RCS Lille Métropole 424 761 419 00045<br></br>
                                Code APE 2620Z<br></br>
                                N° TVA : FR 22 424 761 419<br></br>
                                Siège social : 2 rue Kellermann - 59100 Roubaix - France</p>
                            <h3>Crédit Photo</h3>
                            <p>Pierre Alain Le Guilchard - La Rocade</p>
                            <p><a rel="noreferrer" href="http://larocade.com" target="_blank">www.larocade.com</a></p>
                        </div>
                    </div>
                </div>
            </motion.div>
        );
    }

}

export default Legal;
import { useLocation, Routes, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import {
    Home,
    Contact,
    Location,
    Services,
    Legal,
    Reviews
} from "./components";

const AnimatedRoutes = () => {
    const location = useLocation();

    return (
        <AnimatePresence exitBeforeEnter>
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<Home />} />
                <Route path="/services" element={<Services />} />
                <Route path="/location" element={<Location />} />
                <Route path="/avis" element={<Reviews />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/mentions-legales" element={<Legal />} />
                <Route path="*" element={<Home />} />
            </Routes>
        </AnimatePresence>
    );
};

export default AnimatedRoutes;

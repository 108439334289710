import React from "react";
import ListPinballs from "./ListPinballs";
import { motion } from "framer-motion";

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.9] };

class Location extends React.Component {

    componentDidMount() { }

    render() {
        return (
            <motion.div className="page" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0, transition: transition }}>
                <div className="container">
                    <div className="row align-items-center justify-content-center my-5">
                        <div className="col-lg-8 framed">
                            <p><b>Banana Flip vous propose son service de location.</b></p>
                            <p>Week-end, semaine ou mois, à vous de choisir parmi nos machines disponibles. L’installation est effectuée par nos soins à votre domicile.
                                La location est également disponible pour les entreprises.</p>
                            <p>Parce que chaque flipper possède son propre thème, choisissez parmi ceux disponibles celui qui correspond le plus à vos attentes.</p>
                        </div>
                    </div>
                    <ListPinballs />
                </div>
            </motion.div>
        );
    }
}

export default Location;
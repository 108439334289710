import React from "react";
import { NavLink } from "react-router-dom";

class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.onHomePage = false;

        this.handleItemClick = this.handleItemClick.bind(this)
        this.handleLogoClick = this.handleLogoClick.bind(this)

        window.addEventListener('resize', this.handleResize.bind(this))
    }


    componentDidMount() {
        const brand = document.getElementsByClassName("navbar-brand");
        const ball = document.getElementById("nav-ball");
        if (brand[0].classList.contains('active')) {
            this.onHomePage = true;
            ball.style.opacity = 0;
            ball.style.left = 0 + "px";
        } else {
            this.onHomePage = false;

            const active = document.querySelector('.nav-item .active');
            const ball = document.getElementById("nav-ball");
            if (active !== null)
                ball.style.left = active.offsetLeft + (active.offsetWidth / 2) - 5 + "px";
            ball.style.opacity = 1;
        }
    }

    handleItemClick(e) {
        const target = e.target;
        const ball = document.getElementById("nav-ball");
        ball.style.left = target.offsetLeft + (target.offsetWidth / 2) - 5 + "px";
        ball.style.opacity = 1;
        this.onHomePage = false;
    }
    handleLogoClick(e) {
        this.onHomePage = true;
        const ball = document.getElementById("nav-ball");
        ball.style.left = 0 + "px";
        ball.style.opacity = 0;
    }
    handleResize() {
        const active = document.querySelector('.nav-item .active');
        const ball = document.getElementById("nav-ball");
        if (!this.onHomePage)
            ball.style.left = active.offsetLeft + (active.offsetWidth / 2) - 5 + "px";
    }

    render() {
        return (
            <div className="navigation">
                <nav className="navbar navbar-expand-md navbar-dark bg-dark">
                    <div className="container">
                        <NavLink className="navbar-brand" to="/" onClick={this.handleLogoClick}>
                            {/* <img className="logo-img" src={"/img/logo.png"} alt="Banana Flip" height="56px" /> */}
                            <div className="animated-logo-wrapper">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 315 306.3">
                                    <circle id="logo-ball" className="st1" cx="211.5" cy="99.1" r="36" />
                                    <g id="logo-flipper">
                                        <path className="st2" d="M234.3,261.1c-59-3.5-115.9-39.7-149.7-86.2C68.1,152.2,53,118.1,49.9,89.3C46.6,58.2,73,35.4,104.4,50.1
	c21.4,10,18.8,34.4,26.9,54.1c9.4,23,23.6,44.2,40.4,62.1c18.6,19.7,42.5,35.8,68.5,43.8c13.2,4.1,26.5,8.8,25.5,26
	C264.8,252,249,262.4,234.3,261.1z" />
                                        <circle className="st3" cx="85.5" cy="81.1" r="18" />
                                    </g>
                                    <g id="logo-borders">
                                        <path className="st0" d="M254.8,14.1c23,0,41.7,18.7,41.7,41.7v194.7c0,23-18.7,41.7-41.7,41.7H60.2c-23,0-41.7-18.7-41.7-41.7V55.8
		c0-23,18.7-41.7,41.7-41.7H254.8 M254.8,0.1H60.2c-30.6,0-55.7,25-55.7,55.7v194.7c0,30.6,25,55.7,55.7,55.7h194.7
		c30.6,0,55.7-25,55.7-55.7V55.8C310.5,25.2,285.5,0.1,254.8,0.1L254.8,0.1z" />
                                    </g>
                                </svg>
                            </div>
                            <h1 className="logo-text"><span>BANANA</span><span>FLIP</span></h1>
                        </NavLink>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar1">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbar1">
                            <ul className="navbar-nav ml-auto">
                                <div id="nav-ball"></div>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Services" onClick={this.handleItemClick}>
                                        Services
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Location" onClick={this.handleItemClick}>
                                        Location
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Contact" onClick={this.handleItemClick}>
                                        Contact
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Avis" onClick={this.handleItemClick}>
                                        Avis
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}

export default Navigation;